// Sets if the example should run locally or on chain
export enum Chain {
  MAINNET,
  GOERLI,
}

// Inputs that configure this example to run
interface ExampleConfig {
  chain: Chain;
  rpc: {
    mainnet: string;
    goerli: string;
  };
}

// Example Configuration
export const CurrentConfig: ExampleConfig = {
  chain: Chain.MAINNET,
  rpc: {
    mainnet: "https://mainnet.infura.io/v3/55ecd0b130c846c4b51c19c9a3b924ac",
    goerli: "https://goerli.infura.io/v3/55ecd0b130c846c4b51c19c9a3b924ac",
  },
};
