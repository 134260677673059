import { AppContext } from "../hooks/context";
import {
  setTxSubmittedModal,
  setShouldShowConnectWalletModal,
} from "../hooks/state";
import { Web3Provider } from "@ethersproject/providers";
import styled from "styled-components";
import { FC, useContext } from "react";
import { effectiveMobileMaxWidth } from "../shared/ui/Constants";
import apolloLogo from "../styles/svg/apollo.svg";
import { MintOrConnectButton } from "./MintOrConnectButton";

interface MintProps {
  mintProvider: Web3Provider | null;
}

export const MintSubLabel: FC<MintProps> = ({ mintProvider }) => {
  if (mintProvider != null) {
    return (
      <Label>
        Mint up to 10 at a time
        <br /> Each Mint is .1 Eth + gas fee
      </Label>
    );
  } else {
    return <Label>Mint up to 10</Label>;
  }
};

const Label = styled.h2`
  font-weight: 400;
  font-size: 12px;
  color: #72796f;
  text-align: center;
  margin: 0;
`;
