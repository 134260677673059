import { useWeb3React } from "@web3-react/core";
import { FC, useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../hooks/context";
import { setShouldShowConnectWalletModal } from "../../hooks/state";
import { Account } from "./ConnectWalletModal";
import ReactGA from "react-ga4";

type FontSize = `${number}px` | `${number}%` | `${number}em` | `${number}rem`;

interface ButtonProps {
  fontSize?: FontSize;
}

export const ConnectWalletButton: FC<ButtonProps> = (props) => {
  const { isActive, account } = useWeb3React();
  const { dispatch } = useContext(AppContext);
  const showWalletModal = () => {
    dispatch(setShouldShowConnectWalletModal(true));
    ReactGA.event({
      action: "clickConnectWallet_action",
      category: "connectWallet_category",
    });
  };

  return (
    <ConnectButton type="button" onClick={showWalletModal} {...props}>
      {isActive && <PinkDot />}
      {getConnectWalletText(account, isActive)}
    </ConnectButton>
  );
};

// Helper functions

const getConnectWalletText = (account: Account, active: boolean): string =>
  active && account
    ? account.substring(0, 6) +
      "..." +
      account.substring(account.length - 4, account.length)
    : "Connect Wallet";

// Styled components

const ConnectButton = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0.5rem 1rem;
  font-size: ${(props) => props.fontSize ?? "1rem"};
  font-weight: 500;
  color: #0f121a;
  background: #5dff7a;
  border: 1px solid #5dff7a;
  border-radius: 999px;
  transition: 0.1s linear all;

  &:hover {
    background-color: #b8ffc4;
    cursor: pointer;
  }
`;

// Pink is complementary from Green; can choose something more aesthetic
const PinkDot = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #f75cf0;
`;
