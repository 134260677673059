import { AppContext } from "../hooks/context";
import {
  setTxSubmittedModal,
  setShouldShowConnectWalletModal,
} from "../hooks/state";
import { Web3Provider } from "@ethersproject/providers";
import styled from "styled-components";
import { FC, useContext } from "react";
import { effectiveMobileMaxWidth } from "../shared/ui/Constants";
import apolloLogo from "../styles/svg/apollo.svg";
import { MintOrConnectButton } from "./MintOrConnectButton";
import { MintSubLabel } from "./MintSubLabel";

interface MintProps {
  mintProvider: Web3Provider | null;
}

export const MintWidget: FC<MintProps> = ({ mintProvider }) => {
  const { dispatch } = useContext(AppContext);
  const skillLabelText =
    mintProvider != null
      ? "Mint up to 10 at .1 Eth each + gas fee"
      : "Connect wallet to begin";
  return (
    <CardContainer>
      <LeadingElementsContainer>
        <Image src={apolloLogo} alt="Apollo Crypto DAO logo " />
        <LabelContainer>
          <NameLabel>Apollo NFT Minting Portal</NameLabel>
          <MintSubLabel mintProvider={mintProvider} />
        </LabelContainer>
      </LeadingElementsContainer>
      <MintOrConnectButton mintProvider={mintProvider} />
    </CardContainer>
  );
};

const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  padding: 32px 0px;
  background-color: #ffffff;
  border-radius: 32px;
  border: 1px solid #a1a9bf;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    gap: 8px;
    border-radius: 6px;
    padding: 16px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const LeadingElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    align-items: center;
  }
`;

const NameLabel = styled.h1`
  font-weight: 400;
  font-size: 22px;
  color: #002106;
  margin: 0;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    font-weight: 600;
    font-size: 14px;
  }
`;

const SkillLabel = styled.h2`
  font-weight: 400;
  font-size: 12px;
  color: #72796f;
  margin: 0;
`;

const Image = styled.img`
  width: 88px;
  height: 88px;
  object-fit: cover;
  border-radius: 50%;

  @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
    width: 88px;
    height: 88px;
  }
`;
