export type TxSubmittedModal = {
  modalTxHash: string;
  txStatus:boolean;
};

export type State = {
  shouldShowConnectWalletModal: boolean;
  shouldShowTxSubmittedModal: boolean;
  txSubmittedModal: TxSubmittedModal | null;
};

export const DEFAULT_STATE: State = {
  shouldShowConnectWalletModal: false,
  shouldShowTxSubmittedModal: false,
  txSubmittedModal: null,
};

export const setShouldShowConnectWalletModal = (
  shouldShowConnectWalletModal: boolean
) =>
  ({
    type: "SET_SHOULD_SHOW_CONNECT_WALLET_MODAL",
    payload: {
      shouldShowConnectWalletModal,
    },
  } as const);

export const setTxSubmittedModal = (txSubmittedModal: TxSubmittedModal | null) =>
  ({
    type: "SET_TXSUBMITTED_MODAL_INFO",
    payload: {
      txSubmittedModal,
    },
  } as const);

export const setShouldShowTxSubmittedModal = (shouldShowTxSubmittedModal: boolean) =>
  ({
    type: "SET_SHOULD_SHOW_TXSUBMITTED_MODAL",
    payload: {
      shouldShowTxSubmittedModal,
    },
  } as const);

export type Action =
  | ReturnType<typeof setShouldShowConnectWalletModal>
  | ReturnType<typeof setTxSubmittedModal>
  | ReturnType<typeof setShouldShowTxSubmittedModal>;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_SHOULD_SHOW_CONNECT_WALLET_MODAL":
      return {
        ...state,
        shouldShowConnectWalletModal:
          action.payload.shouldShowConnectWalletModal,
      };
    case "SET_SHOULD_SHOW_TXSUBMITTED_MODAL":
      return {
        ...state,
        shouldShowTxSubmittedModal: action.payload.shouldShowTxSubmittedModal,
      };
    case "SET_TXSUBMITTED_MODAL_INFO":
      return {
        ...state,
        txSubmittedModal: action.payload.txSubmittedModal,
      };
  }
};
