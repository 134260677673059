import { FC, useContext, useState } from "react";
import styled from "styled-components";
import { Modal } from "../Modal";
import { AppContext } from "../../hooks/context";
import { setShouldShowConnectWalletModal } from "../../hooks/state";
import { ConnectionOptions } from "../ConnectionOptions";
import { useWeb3React } from "@web3-react/core";
import { ConnectionType } from "../../connectors/connections";

export type Account = string | null | undefined;

export const ConnectWalletModal: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { isActive } = useWeb3React();
  const [connectionType, setConnectionType] = useState<ConnectionType | null>(
    null
  );
  const isShown = state.shouldShowConnectWalletModal;
  const shouldClose = () => dispatch(setShouldShowConnectWalletModal(false));

  return (
    <Modal
      isShown={isShown}
      shouldClose={shouldClose}
      contentWidth="420px"
      title="Connect a wallet"
    >
      <ModalContent>
        <ConnectionOptions
          activeConnectionType={connectionType}
          isConnectionActive={isActive}
          onActivate={setConnectionType}
          onDeactivate={setConnectionType}
          optionImage="null"
        />
      </ModalContent>
    </Modal>
  );
};

// Styled components

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
