import {
  Dispatch,
  FC,
  createContext,
  useReducer,
  PropsWithChildren,
} from "react";
import { Action, DEFAULT_STATE, reducer, State } from "./state";

type StateWithDispatch = {
  state: State;
  dispatch: Dispatch<Action>;
};

export const AppContext = createContext<StateWithDispatch>(undefined as any);

export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const initialState = DEFAULT_STATE;

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
