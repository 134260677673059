import React, { FC, useEffect } from "react";
import {
  DEFAULT_NOTIFICATION_TEXT,
  SLIPPAGE_TEXT,
  ZEROS_TEXT,
} from "../constants";

// ↓↓↓ Don't forget to import the widgets' fonts! ↓↓↓
import "@uniswap/widgets/fonts.css";
// ↑↑↑
import styles from "../styles/Home.module.css";
import GlobalStyle from "../shared/ui/GlobalStyles";

import { DesktopOrMobileNavBar } from "../navbar/DesktopOrMobileNavBar";
import { ConnectWalletModal } from "../components/wallet/ConnectWalletModal";
import { AppContextProvider } from "../hooks/context";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import { Maintenance } from "../components/Maintenance";
import { TxSubmittedModal } from "../components/TxSubmittedModal";
import ReactGA from "react-ga4";
import { MintWidget } from "../components/MintWidget";

const App: FC = () => {
  const { provider, isActive } = useWeb3React();
  const providerForWidget = isActive && provider != null ? provider : null;
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
    console.log("sent analytics");
  });
  if (process.env.REACT_APP_PUBLIC_MAINTENANCE === "true") {
    return <Maintenance />;
  } else {
    return (
      <Wrapper>
        <GlobalStyle />
        <AppContextProvider>
          <DesktopOrMobileNavBar />

          <ConnectWalletModal />
          <TxSubmittedModal />
          <main className={styles.main}>
            <div className={styles.demo}>
              <MintWidget mintProvider={providerForWidget} />
            </div>
          </main>
          <Footer>
            <div>
              <a
                href="https://www.apolloftw.com/"
                target="_blank"
                rel="noreferrer"
              >
                ApolloFTW.com
              </a>{" "}
              •
              <a
                href="https://www.apolloftw.com/apollo-team-nfts-terms-of-service/"
                target="_blank"
                rel="noreferrer"
              >
                Terms Of Service
              </a>
            </div>
          </Footer>
        </AppContextProvider>
      </Wrapper>
    );
  }
};

export default App;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const HelpAlert1 = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
`;

const HelpAlert2 = styled.div`
  position: absolute;
  top: 12.5rem;
  left: 0.25rem;
  z-index: 1;
`;

const Footer = styled.footer`
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  padding: 0.5rem;

  a {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    padding: 0.75rem;
    text-decoration: none;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

const Notification = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem;
  font-size: 14px;
  font-weight: 600;
  color: #4e5265;
  background-color: #f5f6fb;
  border: 1px solid #d4d9ec;
  border-radius: 18px;
`;

const NotificationText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  color: #79809d;
  max-width: 28em;
  margin: 0;
`;
