import { FC } from "react";
// import { MobileNavBar } from './MobileNavBar';
import { NavBar } from "./NavBar";

export const DesktopOrMobileNavBar: FC = () => (
  <>
    {/* Caleb -- i think we can refactor this and simplify.
        We can use a single NavBar and handle changes inside the component */}
    <NavBar />
    {/* <ConditionalDesktopNavBar>
      <NavBar />
    </ConditionalDesktopNavBar> */}
    {/* <ConditionalMobileNavBar>
      <MobileNavBar queueInfo={queueInfo} />
    </ConditionalMobileNavBar> */}
  </>
);
