import React from "react";
import styled from "styled-components";

import {
  ConnectionType,
  getHasMetaMaskExtensionInstalled,
} from "../connectors/connections";
import { METAMASK_URL } from "../constants";
import { Option } from "./Option";

type ConnectOptionsParams = {
  activeConnectionType: ConnectionType | null;
  isConnectionActive: boolean;
  onActivate: (connectionType: ConnectionType) => void;
  onDeactivate: (connectionType: null) => void;
  optionImage: string;
};

export const ConnectionOptions = ({
  activeConnectionType,
  isConnectionActive,
  onActivate,
  onDeactivate,
  optionImage,
}: ConnectOptionsParams) => {
  function getOptions(isActive: boolean) {
    const hasMetaMaskExtension = getHasMetaMaskExtensionInstalled();

    const isNoOptionActive =
      !isActive || (isActive && activeConnectionType === null);

    const metaMaskOption = hasMetaMaskExtension ? (
      <Option
        isEnabled={
          isNoOptionActive || activeConnectionType === ConnectionType.INJECTED
        }
        isConnected={activeConnectionType === ConnectionType.INJECTED}
        connectionType={ConnectionType.INJECTED}
        onActivate={onActivate}
        onDeactivate={onDeactivate}
        optionImage="/mm.png"
      />
    ) : (
      <ProviderButton
        type="button"
        onClick={() => window.open(METAMASK_URL, "_blank")}
      >
        <img src={"/mm.png"} alt="wallet logo" />
        Install Metamask
      </ProviderButton>
    );

    const coinbaseWalletOption = (
      <Option
        isEnabled={
          isNoOptionActive ||
          activeConnectionType === ConnectionType.COINBASE_WALLET
        }
        isConnected={activeConnectionType === ConnectionType.COINBASE_WALLET}
        connectionType={ConnectionType.COINBASE_WALLET}
        onActivate={onActivate}
        onDeactivate={onDeactivate}
        optionImage="/cbw.png"
      />
    );

    const walletConnectOption = (
      <Option
        isEnabled={
          isNoOptionActive ||
          activeConnectionType === ConnectionType.WALLET_CONNECT
        }
        isConnected={activeConnectionType === ConnectionType.WALLET_CONNECT}
        connectionType={ConnectionType.WALLET_CONNECT}
        onActivate={onActivate}
        onDeactivate={onDeactivate}
        optionImage="/wc.png"
      />
    );

    return (
      <>
        {metaMaskOption}
        {coinbaseWalletOption}
        {walletConnectOption}
      </>
    );
  }

  return (
    <ConnectorsContainer className="connectors">
      {getOptions(isConnectionActive)}
    </ConnectorsContainer>
  );
};

const ConnectorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  padding: 0 1rem;
`;

const ProviderButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #0f121a;
  background-color: #e5e9fa;
  border: none;
  border-radius: 12px;
  transition: 0.1s linear all;

  &:disabled {
    opacity: 0.6;
  }
  &:not(:disabled):hover {
    background-color: rgba(173, 188, 255, 0.4);
    cursor: pointer;
  }

  img {
    border-radius: 3px;
    width: 25px;
    height: 25px;
  }
`;
