import React, { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../hooks/context";
import { setShouldShowConnectWalletModal } from "../hooks/state";

import {
  ConnectionType,
  getConnection,
  tryActivateConnector,
  tryDeactivateConnector,
} from "../connectors/connections";

export const Option = ({
  isEnabled,
  isConnected,
  connectionType,
  onActivate,
  onDeactivate,
  optionImage,
}: {
  isEnabled: boolean;
  isConnected: boolean;
  connectionType: ConnectionType;
  onActivate: (connectionType: ConnectionType) => void;
  onDeactivate: (connectionType: null) => void;
  optionImage: string;
}) => {
  const { dispatch } = useContext(AppContext);
  const shouldClose = () => dispatch(setShouldShowConnectWalletModal(false));
  const onClick = async () => {
    if (isConnected) {
      const deactivation = await tryDeactivateConnector(
        getConnection(connectionType).connector
      );
      // undefined means the deactivation failed
      if (deactivation === undefined) {
        return;
      }
      onDeactivate(deactivation);
      shouldClose();
      return;
    }

    const activation = await tryActivateConnector(
      getConnection(connectionType).connector
    );
    if (!activation) {
      return;
    }
    onActivate(activation);
    shouldClose();
    return;
  };

  return (
    <ProviderButton type="button" onClick={onClick} disabled={!isEnabled}>
      <img src={`${optionImage}`} alt="wallet logo" />{" "}
      {`${isConnected ? "Disconnect" : `${connectionType}`}`}
    </ProviderButton>
  );
};

// duplicated in ConnectionOptions.tsx - but not sure how to refactor
const ProviderButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #0f121a;
  background-color: #e5e9fa;
  border: none;
  border-radius: 12px;
  transition: 0.1s linear all;

  &:disabled {
    opacity: 0.6;
  }
  &:not(:disabled):hover {
    background-color: rgba(173, 188, 255, 0.4);
    cursor: pointer;
  }

  img {
    border-radius: 3px;
    width: 25px;
    height: 25px;
  }
`;
