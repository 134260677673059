import { createGlobalStyle } from "styled-components";
import { effectiveMobileMaxWidth } from "./Constants";

const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        background-color: #fff;
        height: 100%;
        font-family: system-ui, "Inter", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    }

    body {
        background: linear-gradient(
            180deg,
            rgba(0, 220, 85, 0.1) 0%,
            rgba(0, 220, 85, 0) 100%
            ),
            #fff;
    }

    * {
        font-family: inherit;
        box-sizing: border-box;
    }

    @media screen and (max-width: ${effectiveMobileMaxWidth()}px) {
        .hide-on-mobile {
            display: none;
        }
    }

    @keyframes pulse {
        0% {
          transform: scale(0.6);
          opacity: 0;
        }
        70% {
          transform: scale(2.5);
          opacity: 1;
        }
        100% {
          transform: scale(3.5);
          opacity: 0;
        }
    }
`;

export default GlobalStyle;
