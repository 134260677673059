import { FC } from "react";
import styled from "styled-components";
import { effectiveMaxWidth } from "../shared/ui/Constants";
import apolloLogo from "../styles/svg/apollo.svg";
import { ConnectWalletButton } from "../components/wallet/ConnectWallet";

export const NavBar: FC = () => {
  return (
    <>
      <Section className="navbar">
        <Content>
          <Links>
            <ApolloLogo />
            <PageTitle>
              Apollo NFT
              <br />
              Minting Portal
            </PageTitle>
          </Links>
          <ConnectWalletButton />
        </Content>
      </Section>
    </>
  );
};

export const ApolloLogo: FC = () => (
  <StyledApolloLogo src={apolloLogo} alt="Apollo Crypto DAO logo " />
);

const Section = styled.header`
  background-color: #fff;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  max-width: ${effectiveMaxWidth()}px;
  padding: 0.5rem 0;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledApolloLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;

const PageTitle = styled.span`
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 0.9;
`;
